.MuiGrid-root {
    margin-top: 5px !important;
    color: rgb(255, 255, 255) !important;        /* Entire Div */
    display: block !important;
}

.MuiGrid-root > .MuiGrid-item {
    margin-left: 18px !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
}

.Calendar .MuiInputBase-root {
    color: rgb(0, 0, 0) !important;      /* Blocks */
    border-radius: "10px";
    background-color: #ffffff !important;
    margin-right: 5px;
    height: 40px !important;
    width: 112px !important;
}

.rmdp-input {               /* Input */
    min-width: 200px !important;
    min-height: 47px !important;
    height: 100% !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    background: rgb(255, 255, 255) !important;
    padding: 15px 30px !important;
    color: rgb(0, 0, 0) !important;
    border: 0 !important;
    border-radius: 5px !important;
    margin-left: 10px !important;
}

.MuiFormLabel-root {
    color: rgb(0, 0, 0) !important; 
    margin-top: -8px; 
              /* Input field */
}

fieldset {
    border-style: none !important;     /* Border Blocks */
}

.MuiInputLabel-root {
    color: black;    
}

.ValidateDates {
    margin-left: 50px;
    background: #CEFD34;
    color: rgba(2, 35, 75, 1) !important;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    margin-right: 20px;
    font-weight: 600;
    cursor: pointer;
}

.DatePicker {
    display: flex !important;
}

.Calendar {
    align-items: center;
}