.profile_button {
    display: flex;
    float: right;
    margin-right: 3%;
    background: transparent;
    font-size: 17px;
    color: white;
    margin-top: 25px;
    border-width: 0px;
    cursor: pointer;
}

.profile_button:hover {
    display: flex;
    float: right;
    margin-right: 3%;
    background: transparent;
    font-size: 17px;
    color: #CEFD34;
    margin-top: 25px;
    border-width: 0px;
}

.profile_button .profile_icon {
    border-radius: 20px;
    background: grey;
    margin-top: -2px;
    margin-right: 25px;
}