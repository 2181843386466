
.page .page_title {
    margin-left: 40px;
    color: white;
    font-size: 30px;
}

.page .new_button {
    display: flex;
    margin-left: 50px;
    background: #CEFD34;
    color: rgba(2, 35, 75, 1) !important;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;
}

.page .new_button:hover {
    display: flex;
    margin-left: 50px;
    background: #e1ff7e;
    color: rgba(2, 35, 75, 1);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;
}

.page .new_button .new_icon {
    margin-top: 1px;
    margin-bottom: auto;
}

.page .table {
    margin-left: 40px;
    margin-right: 40px;
}

.link {
    color: #0D366F;
    font-weight: 600;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.cardBdd {
    background: rgba(2, 35, 75, 1);
    margin: 20px;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.cardBdd:hover {
    box-shadow: 5px 6px 13px 0px black;
}

.cardBdd .title {
    margin-bottom: 30px;
}

.cardBdd .link {
    text-align: right;
    margin-bottom: 10px;
}
