* {
  margin: 0;
  padding: 0;
  background: transparent;
  box-sizing: border-box;
}

.page {
  background: linear-gradient(180deg, rgba(2, 26, 52, 1) 0%, rgba(2, 57, 98, 1) 100%) fixed;
  flex-basis: 85%;
  height: auto !important;
  min-height: 100vh;
  overflow-x: auto;
}


.sidebar {
  top: 0;
  left: 0;
  min-height: 100vh;
  flex-basis: 15%;
  background: #ffffff;
  z-index: 100;
  transition: all 0.5s ease;
}

.sidebar .nav-links hr {
  display: block;
  height: 1px;
  border: 1;
  color: rgba(2,35,75,1);
  margin: 0;
  padding: 0;
}

.sidebar .logo_name {
  position: absolute;
  left: 3.5%;
  margin-top: 20px;
  font-size: 28px;
  color: rgba(2,35,75,1);
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.sidebar .nav-links {
  height: 100%;
  padding: 70px 0 150px 0;
  overflow: auto;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover {
  background: rgba(2, 56, 98, 0.664);
  cursor: pointer;
}

.sidebar .nav-links li .icon_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.sidebar .nav-links li i {
  margin-left: 20px;
  margin-right: 20px;
  height: 50px;
  line-height: 60px;
  color: rgba(2,35,75,1);
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .icon_link:hover i {
  color: rgb(0, 0, 0);
}

.sidebar .nav-links li .icon_link.showMenu i.arrow {
  transform: rotate(90deg);
}

.sidebar .nav-links li p {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.is-open {
  display: block;
}

.sidebar .nav-links li p .link_name {
  font-size: 16px;
  font-weight: 600;
  color: rgba(2,26,52,1);
  transition: all 0.4s ease;
}

.sidebar .nav-links li:hover p .link_name {
  color: rgb(0, 0, 0);
}

.sub-menu {

  background: rgb(255, 255, 255);
  display: none;
}

.sidebar .nav-links li .icon_link.showMenu .sub-menu {
  display: block;
}

.sub-menu.showSubMenu{
display: block;
}
.sidebar .nav-links li .sub-menu p {
  color: rgba(2,35,75,1);
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 50px;
  font-weight: 600;
  white-space: nowrap;
  transition: all 0.3s ease;
  display: none;
}
.sidebar .nav-links li .sub-menu p {
  display: block;
}

.sidebar .nav-links li .sub-menu p:hover {
  opacity: 1;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu{
    display: none;
  }
}
