.inputSelect {
    min-width: 400px;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.dropdownSelect {
    background: white;
}

.element_dropdown {
    margin-top: -5px;
    background: white;
    padding: 10px;
    cursor: pointer;
}

.element_dropdown:hover {
    background: lightgrey;
}
