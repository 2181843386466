.breadcrumbFront {
    display: flex;
    padding-top: 20px;
    padding-left: 5px;
    font-size: var(--breadcrumb-desktop-size)
}


.home_icon {
    color: white;
    width: 2%;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;

}

.home_icon:hover {
    margin-top: auto;
    margin-bottom: auto;
    color: #CEFD34;
}

.arrow_icon {
    color: white;
    margin: auto 10px;
    font-size: 10px !important;
}

.text {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
    pointer-events: none;
}


.text_here {
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
    text-decoration: underline 1px;
}

.text_here:hover {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 600;
    color: #CEFD34;
}

.text:hover {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 600;
    color: #CEFD34;
}
