.images {
    margin-top: 20px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.images .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.images .icon {
    display: flex;
    color: white;
    height: 50px;
    position: relative;
    left: -3%;
    background: transparent;
}

.images .name .input {
    min-width: 400px;
    height: 70px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.images .state {
    margin-top: 4px;
    margin-bottom: 6px;
    margin-right: 20px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    margin-left: 5%;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.delete_button_doc {
    background: #e01313;
    color: rgb(228, 228, 228);
    display: flex;
    margin-top: 15px;
    height: 40px;
    margin-left: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;

}

.delete_button_doc:hover {
    background: #ff7e7e;
    color: rgb(228, 228, 228);
    display: flex;
    margin-left: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;
}

.delete_button_doc .delete_icon {
    margin-top: auto;
    margin-right: 5px;
    margin-bottom: auto;
}


.coordonnees.showMenu {
    flex-direction: column;
    display: flex;
}

.names {
    margin-top: 50px;
    margin-right: 20px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.names .name {
    display: flex;
    background: transparent;
}

.names .name .name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    background: transparent;
}

.names .input {
    min-width: 400px;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.names .name .state-country {
    margin-left: 2px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    max-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.names .name .state {
    margin-top: 4px;
    margin-bottom: 6px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.status .name .state-country {
    margin-left: 2px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    max-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.status {
    margin-top: 50px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.status .input {
    min-width: 400px;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.status .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.status .name .name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    background: transparent;
}

.status .name .state {
    margin-top: 4px;
    margin-bottom: 6px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.names-variante {
    margin-right: 20px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.names-variante .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.names-variante .name .name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    background: transparent;
}

.names-variante .input-title {
    width: 65%;
    margin-right: 5%;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}


.names-variante .input-price {
    width: 15%;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.button-add {
    background: #CEFD34;
    color: rgba(2,35,75,1);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 800;
    cursor: pointer;
}

.button-remove {
    margin: 10px 0px 10px 10px;
    background: #ff0000;
    color: white;
    padding: 0px 10px;
    border-width: 0;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 800;
    cursor: pointer;
}

.subInfos {
    display: flex;
    flex-direction: column;
}

.subInfos .infos .state {
    margin-top: 10px;
    margin-bottom: 10px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    max-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
}

.infos {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 20px;
    background: #223a54;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.infos .input {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    min-width: 400px;
    max-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.infos .state {
    margin-bottom: 20px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.vehicules .am-button {
    min-width: 250px;
    min-height: 40px;
    margin-top: 5px;
    margin-bottom: 10px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;

}

.vehicules .am-button:hover {
    min-width: 250px;
    min-height: 40px;
    margin-top: 5px;
    appearance: none;
    background: white;
    border-radius: 20px;
    color: rgb(0, 0, 0);
    font-weight: 600;

}

.vehicules.showMenu {
    flex-direction: column;
    display: flex;
    appearance: none;
}

.vehicules .first-subTitles {
    text-align: center;
    margin-bottom: 30px;
    margin-left: 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.vehicules .vehicule-status {
    margin-top: 20px;
    margin-right: 20px;
    background: #223a54;
    border-radius: 10px;
    padding-top: 30px;
    padding-left: 30px;
    width: 570px;
}


.vehicules .vehicule-names {
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.vehicules .vehicule-names .vehicule-name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.vehicule-name .vehicule-name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    background: transparent;
}

.vehicules .vehicule-status .state {
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
}

.vehicule-first-subTitles {
    margin-top: 10px;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.segmentVehicle {
    margin-top: 20px;
    flex-basis: 20%;
    background: #223a54;
    margin-right: 30px;
    border-radius: 10px;
    padding: 30px;
}
.segmentVehicleContainer {
    display: flex;
    flex-wrap: wrap;
}

.presentations.showMenu {
    flex-direction: column;
    display: flex;
    appearance: none;
}

.specs {
    margin-top: 50px;
    margin-right: 20px;

    background: #223a54;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.specs .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.specs .name .icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.specs .name .false-icon {
    display: flex;
    color: transparent;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.specs .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.specs .name .state {
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.pres .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.pres {
    margin-top: 50px;

    background: #223a54;
    border-radius: 10px;
    padding: 30px;
    height: auto;
}

.pres .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.pres .name .icon {
    display: flex;
    color: white;
    position: relative;
    margin-top: 10px;
    left: -3%;
    background: transparent;
}

.video {
    margin-top: 20px;
    margin-left: 20px;

    height: 270px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.video .name {
    display: flex;
    background: transparent;
    padding: 10px;
}


.video .name-icon {
    display: flex;
    color: white;
    height: 50px;
    margin-right: -16px;
    position: relative;
    left: -3%;
    background: transparent;
}

.video .icon {
    display: flex;
    color: white;
    height: 50px;
    position: relative;
    left: -3%;
    background: transparent;
}

.video .name .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.video .state {
    margin-top: 4px;
    margin-bottom: 6px;
    margin-right: 20px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    margin-left: 5%;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}
.licences .am-button {
    min-width: 250px;
    min-height: 40px;
    margin-top: 5px;
    margin-right: 40px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;

}

.licences .am-button:hover {
    min-width: 250px;
    min-height: 40px;
    margin-top: 5px;
    margin-right: 40px;
    appearance: none;
    background: white;
    border-radius: 20px;
    color: rgb(0, 0, 0);
    font-weight: 600;

}

.licences.showMenu {
    flex-direction: column;
    display: flex;
    appearance: none;
}

.licences .licence-titles {
    margin-top: 70px;
    margin-bottom: 30px;
    margin-left: 25px;
    color: white;
    font-size: 25px;
    font-weight: 600;
}

.licences .first-subTitles {
    text-align: center;
    margin-bottom: 30px;
    margin-left: 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.licences .subTitles {
    margin-top: 40px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.admises-FFM {
    margin-top: 20px;
    width: 310px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.homologations {
    margin-top: 20px;
    width: 20%;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.homologations-FFSA {
    margin-top: 20px;
    margin-right: 20px;
    width: 310px;
    height: 200px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.homologations-FFM {
    margin-top: 20px;
    margin-right: 20px;
    width: 310px;
    height: 200px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.homologations-UFOLEP {
    margin-top: 20px;
    margin-right: 20px;
    width: 310px;
    height: 160px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.circuitMenus {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
    justify-content: flex-start;
}

.buttonTabs {
    color: white;
    background: #223a54;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    margin-right: 5px;
    margin-bottom: 10px;
    border-width: 0;
    border-radius: 10px;
    cursor: pointer;
}

.buttonTabs.showActiveBackground {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    margin-right: 5px;
    margin-bottom: 10px;
    border-width: 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
    color: #223a54;
}

.buttonTabs.showActiveBackground .tabs-icon {
    color: #223a54;
    margin-right: 20px;
}

.buttonTabs:hover {
    color: #223a54;
    background: #ffffff;
    transition: 0.2s ease;
}

.buttonTabs .tabs-icon {
    color: white;
    margin-right: 20px;
}

.buttonTabs:hover .tabs-icon {
    color: #223a54;
    margin-right: 20px;
}

.subCoord {
    display: flex;
    flex-wrap: wrap;
}

.about {
    margin-top: 20px;
    margin-right: 20px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.about .name {
    display: flex;
    background: transparent;
    padding: 10px;
}


.about .prop-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.about .icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.about .name .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.about .name .state {
    margin-top: 4px;
    margin-bottom: 6px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.type {
    margin-top: 20px;
    margin-left: 20px;

    background: #223a54;
    border-radius: 10px;
    padding: 30px;
    height: auto;
}

.type .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.type .circuit-type {
    min-width: 400px;
    min-height: 45px;
    background: pink;
    border-radius: 5px;
    margin-top: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
}


.subPres {
    display: flex;
    flex-wrap: wrap;
}

.am-button {
    width: 50%;
    margin-top: 5px;
    padding: 10px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;

}

.am-button.actif {
    appearance: none;
    background: white !important;
    color: rgb(0, 0, 0) !important;
}

.am-button:hover {
    appearance: none;
    background: white;
    color: rgb(0, 0, 0);
}

.amenagements.showMenu {
    flex-direction: column;
    display: flex;
    appearance: none;
}

.first-subTitles {
    margin-bottom: 5px;
    margin-left: 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.second-subTitles {
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: white;
    font-size: 18px;
    font-weight: 600;
}

.subTitles {
    margin-top: 40px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.dl-icon {
    display: flex;
}


.public {
    margin-top: 20px;
    width: 50%;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.public .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.public .icon {
    display: flex;
    color: white;
    height: 50px;
    position: relative;
    left: -3%;
    background: transparent;
}

.public .name .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.public .state {
    margin-top: 4px;
    margin-bottom: 6px;
    margin-right: 20px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    margin-left: 5%;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.piste {
    width: 35%;
    margin-top: 20px;
    margin-left: 20px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.piste .name {
    display: flex;
    background: transparent;
    padding: 10px;
}


.piste .name-icon {
    display: flex;
    color: white;
    height: 50px;
    margin-right: -16px;
    position: relative;
    left: -3%;
    background: transparent;
}

.piste .icon {
    display: flex;
    color: white;
    height: 50px;
    position: relative;
    left: -3%;
    background: transparent;
}

.piste .name .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.piste .state {
    margin-top: 4px;
    margin-bottom: 6px;
    margin-right: 20px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    margin-left: 5%;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.subAdress {
    display: flex;
    flex-wrap: wrap;
}

.adresses.showMenu {
    flex-direction: column;
    display: flex;
    appearance: none;
}

.lieu {
    margin-top: 50px;
    margin-right: 20px;
    background: #223a54;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.lieu .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.lieu .name .name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.lieu .name .false-icon {
    display: flex;
    color: transparent;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.lieu .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.lieu .state {
    margin-top: 4px;
    margin-bottom: 6px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.gps .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.gps {
    margin-top: 50px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
    height: auto;
}

.gps .name {
    display: flex;
    background: transparent;
    padding: 10px;
}

.gps .name .name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.departement {
    margin-top: 20px;
    background: #223a54;
    border-radius: 10px;
    padding: 30px;
}

.departement .name {
    display: flex;
    background: transparent;
    padding: 10px;
}


.departement .name .name-icon {
    display: flex;
    color: white;
    height: 50px;
    font-size: 30px;
    margin-left: -25px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.departement .name .false-icon {
    display: flex;
    color: transparent;
    height: 50px;
    font-size: 30px;
    margin-left: -25px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.departement .name .input {
    min-width: 400px;
    background: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.departement .state {
    margin-top: 4px;
    margin-bottom: 6px;
    margin-right: 20px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    margin-left: 5%;
    min-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.state-country {
    margin-left: 2px;
    background: white;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    max-width: 400px;
    color: rgb(0, 0, 0);
    border-width: 0;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
}

.name-icon {
    display: flex;
    color: rgb(255, 255, 255);
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.false-icon {
    display: flex;
    color: transparent;
    height: 50px;
    font-size: 30px;
    margin-left: -10px;
    margin-right: 15px;
    position: relative;
    background: transparent;
}

.button-add-block {
    margin-top: 20px;
    background: #CEFD34;
    color: rgba(2,35,75,1);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 800;
    cursor: pointer;
}

.label {
    color: white;
    margin-top: 10px;
    margin-left: 50px;
    margin-bottom: 5px;
}

.label-2 {
    color: white;
    margin-top: 10px;
    margin-bottom: 5px;
}

.am-button-offer {
    width: 150px;
    margin-top: 15px;
    margin-right: 10px;
    padding: 10px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.am-button-doc {
    width: 300px;
    margin-top: 15px;
    margin-right: 10px;
    padding: 10px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.am-button-doc-big {
    width: 600px;
    margin-top: 15px;
    margin-right: 10px;
    padding: 10px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.am-button-offer.actif {
    appearance: none;
    background: white !important;
    color: rgb(0, 0, 0) !important;
}

.am-button-offer:hover {
    appearance: none;
    background: white;
    color: rgb(0, 0, 0);
}

.variante {
    width: 90%;
    margin-top: 15px;
    padding: 10px;
    background: transparent;
    border-color: transparent;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;

}

.variante .variante-name {
    width: 75%;
    padding: 10px;
    background: white;
    border-color: black;
    border-style: none;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
    margin-right: 5px;
}

.variante .variante-price {
    width: 20%;
    padding: 10px;
    background: white;
    border-color: black;
    border-style: none;
    border-radius: 10px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    cursor: pointer;
}

.input-offer-title {
    min-width: 300px;
    max-height: 47px;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    margin-right: 10px !important;
}

.input-offer-video {
    min-width: 400px;
    min-height: 65px;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    margin-right: 10px !important;
}

.input-offer-price {
    min-width: 130px;
    max-height: 47px;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
}

.state-offer {
    min-width: 200px;
    max-height: 47px;
    appearance: none;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    margin-left: 10px !important;
}

.delete-offer {
    min-width: 80px;
    appearance: none;
    background: rgb(255, 0, 0);
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    font-weight: 900;
    cursor: pointer;
    font-size: 15px;
    margin-left: 10px !important;
}

.modify-offer {
    min-width: 80px;
    appearance: none;
    background: rgb(0, 201, 251);
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    font-size: 15px;
    border: 0;
    border-radius: 5px;
    font-weight: 900;
    cursor: pointer;
    margin-left: 10px !important;
}

.input-offer-desc {
    width: 100%;
    background: white;
    padding: 15px 30px;
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 5px;
    margin: 10px 0px;
}

.option-offer > div {
    background-color: #223a54;
    border-radius: 20px;
    margin-top: 50px;
}

.option-offer {
    margin-top: 50px;
}

.am-button-lvl {
    width: 200px;
    margin-top: 5px;
    padding: 10px;
    background: transparent;
    border-color: white;
    border-width: 2px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;

}

.am-button-lvl.actif {
    appearance: none;
    background: white !important;
    color: rgb(0, 0, 0) !important;
}

.am-button-lvl:hover {
    appearance: none;
    background: white;
    color: rgb(0, 0, 0);
}
