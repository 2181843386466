.page .modify_button {
    display: flex;
    margin-left: 50px;
    background: #CEFD34;
    color: rgba(2,35,75,1);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;

}


.page .modify_button:hover {
    display: flex;
    margin-left: 50px;
    background: #e1ff7e;
    color: rgba(2,35,75,1);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;
}

.page .modify_button .modify_icon {
    margin-top: auto;
    margin-right: 5px;
    margin-bottom: auto;
    transform: rotate(-90deg);
    transition: all 0.9s ease;
}

.page .modify_button:hover .modify_icon {
    transform: rotate(90deg);
    transition: all 0.9s ease;
}

.page .delete_button {
    background: #e01313;
    color: rgb(228, 228, 228);
    display: flex;
    margin-left: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;

}

.page .delete_button:hover {
    background: #ff7e7e;
    color: rgb(228, 228, 228);
    display: flex;
    margin-left: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-width: 0;
    border-radius: 20px;
    font-weight: 800;
    cursor: pointer;
}

.page .delete_button .delete_icon {
    margin-top: auto;
    margin-right: 5px;
    margin-bottom: auto;
}
